export const background = [{
    id: 1,
    title: 'Skills',
    class: 'skills',
    image: '/assets/images/skills.png',
    techStack: [
        'HTML',
        'CSS',
        'SASS',
        'KendoUI',
        'MUI',
        'JavaScript',
        'TypeScript',
        'jQuery',
        'React',
        'Web APIs',
        'Axios',
        'NodeJS',
        'ExpressJS',
        'MongoDB',
        'RESTful APIs',
        'Gulp',
        'Git',
        'NPM',
        'Agile/Scrum',
        'Jira',
        'Responsive Design/Mobile first',
        'Performance Optimization',
    ],
},
{
    id: 2,
    title: 'Experience',
    class: 'experience',
    image: '/assets/images/experience.png',
    techStack: [
        '05/2023 - 05/2024 Front End Developer @ CoreHealth Technologies',
        '01/2023 - 04/2023 Full Stack Developer @ WIIT',
        '11/2022 - 04/2023 Front End Developer @ JobAutoPilot',
        '03/2019 - 03/2020 Web Developer @ Meltek'
    ]
},
{
    id: 3,
    title: 'Education',
    class: 'education',
    image: '/assets/images/education.png',
    techStack: [
        '02/2023 MERN Course @ Udemy',
        '01/2022 Web Development Program @ BrainStation',
        '08/2021 Programming Fundamentals @ CodeCoreCollege',
        '01/2019 Web Development Course @ Udemy',
    ]
}]