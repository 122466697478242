import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import './Header.scss';
import logo from '../../assets/icons/logo.png';
import DynamicReactIcon from '../DynamicReactIcon/DynamicReactIcon';
import { menuItems } from '../../data/navLinks';

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);

  const clickToggle = () => {
    setIsOpened(!isOpened);
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className="header" id="home">
      <Link to="/" className="header__link-logo">
        <img
          src={logo}
          alt="VK"
          className="header__icon"
          width="2.5rem"
          height="2.5rem"
        />
        <div
          className="header__container-logo"
          onClick={scrollTop}
          aria-hidden="true"
        >
          <h1 className="header__logo">Veranika Karpava</h1>
        </div>
      </Link>
      <nav className="header__navigation">
        <div
          className="header__menu-icon"
          onClick={clickToggle}
          aria-hidden="true"
        >
          {!isOpened ? (
            <DynamicReactIcon
              name="BiMenuAltLeft"
              className="header__ham-menu"
            />
          ) : (
            <DynamicReactIcon name="CgClose" className="header__ham-menu" />
          )}
        </div>
        <ul
          className={
            !isOpened
              ? 'header__nav-menu'
              : 'header__nav-menu header__nav-menu--active'
          }
          onClick={clickToggle}
          aria-hidden="true"
        >
          {menuItems.map((item, i) => {
            return (
              <li className="header__item" key={i}>
                <NavLink
                  smooth
                  to={`#${item.hashPath}`}
                  className="header__link-item"
                  activeClassName="header__link-item--selected"
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
