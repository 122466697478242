import React from 'react';

import './ProjectItem.scss';
import DynamicReactIcon from '../DynamicReactIcon/DynamicReactIcon';
import Tag from '../Tag/Tag';
import Button from '../Button/Button';

const ProjectItem = ({
  title,
  link,
  description,
  techStack,
  path,
  video,
  order,
  demo,
  dataAos,
}) => {
  return (
    <li
      className={
        order === 'white' ? 'projects__item-reverse' : 'projects__item'
      }
      data-aos={dataAos}
    >
      <div className="projects__image-container">
        <img src={link} alt={`${title}`} className="projects__image" />
      </div>
      <div className="projects__text-container">
        <h2 className="projects__title-text">{title}</h2>
        <p className="projects__description">{description}</p>
        <div className="projects__techStack">
          {techStack.map((item, i) => (
            <Tag title={item} key={i} />
          ))}
        </div>
        <div className="projects__button-container">
          {video.length === 0 ? (
            ''
          ) : (
            <Button
              title="Video"
              path={video}
              target="blank"
              icon={
                <DynamicReactIcon
                  name="MdOndemandVideo"
                  className="button__icon-button"
                />
              }
            />
          )}
          {demo.length === 0 ? (
            ''
          ) : (
            <Button
              title="Demo"
              path={demo}
              target="blank"
              icon={
                <DynamicReactIcon
                  name="BsBoxArrowUpRight"
                  className="button__icon-button"
                />
              }
            />
          )}
          {path.length === 0 ? (
            ''
          ) : (
            <Button
              title="GitHub"
              path={path}
              target="blank"
              icon={
                <DynamicReactIcon
                  name="AiFillGithub"
                  className="button__icon-button"
                />
              }
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default ProjectItem;
