import React from 'react';

import './Tag.scss';

const Tag = ({ title }) => {
  return (
    <div className="tag">
      <p className="tag__title">{title}</p>
    </div>
  );
};

export default Tag;
