import React from 'react';

import './BackgroundItem.scss';
import Tag from '../Tag/Tag';

const BackgroundItem = ({ style, title, link, techStack, dataAos }) => {
    return (
        <li className={`background__item ${style}`} data-aos={dataAos}>
            <div className="background__image-container">
                <h2 className='background__subheading'>{title}</h2>
                <img src={link} alt={`${title}`} className="background__image" />
            </div>
            <div className="background__text-container">
                <h2 className="background__title-text">{title}</h2>
                <div className="background__techStack">
                    {techStack.map((item, i) => (
                        <Tag title={item} key={i} />
                    ))}
                </div>
            </div>
        </li>
    );
};

export default BackgroundItem;