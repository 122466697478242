import React from 'react';

import './Background.scss';
import BackgroundItem from '../BackgroundItem/BackgroundItem';
import { background } from '../../data/background';

const Background = () => {

  return (
    <section className="background" id="background">
      <h1 className="background__heading">My Background</h1>
      <ul className="background__list">
        {background.map((item) => {
          return (
            <BackgroundItem
              key={item.id}
              style={item.class}
              title={item.title}
              link={item.image}
              techStack={item.techStack}
              dataAos="fade-right"
            />
          );
        })}
      </ul>
    </section>
  );
};

export default Background;
