import React, { useState } from 'react';
import TypeAnimation from 'react-type-animation';

import './Contact.scss';
import message from '../../assets/icons/message.svg';
import Icon from '../IconComponent/Icon';
import Form from '../Form/Form';
import { contact } from '../../data/contacts';
import DynamicReactIcon from '../DynamicReactIcon/DynamicReactIcon';

const Contact = () => {
  const [toSend, setToSend] = useState({
    name: '',
    whom: 'Veranika',
    message: '',
    email: '',
  });

  return (
    <section className="contact" id="contact">
      <h1 className="contact__heading">Get in Touch</h1>
      <div className="contact__content">
        <div className="contact__type-contact">
          <ul className="contact__list-icons">
            {contact.map((item, i) => {
              return (
                <Icon className="contact__item-icon" key={i}>
                  <a
                    className="contact__link"
                    href={item.contactUrl}
                    target="blank"
                    aria-label={item.name}
                  >
                    <DynamicReactIcon
                      name={item.icon}
                      className="contact__icon"
                    />
                  </a>
                </Icon>
              );
            })}
          </ul>
          <img src={message} alt="message" className="contact__message" />
          <TypeAnimation
            cursor={true}
            sequence={[
              'Nice to see you here.',
              1000,
              'Thank you for checking it out.',
              1000,
              'Cheers, Veranika Karpava',
              5500,
            ]}
            wrapper="p"
            repeat={Infinity}
            speed={1}
            className="contact__greeting"
          />
        </div>
        <Form toSend={toSend} setToSend={setToSend} />
      </div>
    </section>
  );
};

export default Contact;
