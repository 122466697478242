export const menuItems = [
  {
    title: 'About',
    hashPath: 'about',
  },
  {
    title: 'Background',
    hashPath: 'background',
  },
  {
    title: 'Projects',
    hashPath: 'projects',
  },
  {
    title: 'Contact',
    hashPath: 'contact',
  },
];
