export const contact = [
  {
    name: 'Gmail',
    contactUrl: 'mailto:veranika.karpava@gmail.com',
    icon: 'SiGmail',
  },
  {
    name: 'LinkedIn',
    contactUrl: 'https://www.linkedin.com/in/veranikakarpava',
    icon: 'SiLinkedin',
  },
  {
    name: 'GitHub',
    contactUrl: 'https://github.com/veranika-karpava',
    icon: 'SiGithub',
  },
  {
    name: 'Resume',
    contactUrl: '/veranika-karpava-web-developer-resume.pdf',
    icon: 'AiOutlineFile',
  },
];
