import React from 'react';

const Icon = ({ children, showTitle, title, className, dataAos }) => {
  return (
    <li className={className} data-aos={dataAos}>
      {children}
      {showTitle && <p className="skills__title">{title}</p>}
    </li>
  );
};

export default Icon;
