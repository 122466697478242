import React from 'react';

import { icons } from '../../data/iconsList';

const DynamicReactIcon = ({ name, className }) => {
  const DynamicIcon = icons[name];
  return <DynamicIcon className={className} />;
};

export default DynamicReactIcon;
