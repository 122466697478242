import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

import './App.scss';
import Header from './components/Header/Header';
import AboutMe from './components/AboutMe/AboutMe';
import Background from './components/Background/Background';
import ProjectsList from './components/ProjectsList/ProjectsList';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <AboutMe />
      <Background />
      <ProjectsList />
      <Contact />
      <Footer />
    </BrowserRouter>
  );
};

export default App;
