import {
  FaHtml5,
  FaCss3Alt,
  FaSass,
  FaReact,
  FaNodeJs,
  FaHeart,
  FaDocker,
} from 'react-icons/fa';
import { DiGit, DiMongodb } from 'react-icons/di';
import {
  SiExpress,
  SiMaterialui,
  SiJavascript,
  SiTypescript,
  SiGmail,
  SiLinkedin,
  SiGithub,
} from 'react-icons/si';
import { GrMysql } from 'react-icons/gr';
import { MdOndemandVideo } from 'react-icons/md';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { AiFillGithub, AiOutlineFile } from 'react-icons/ai';
import { FiSend } from 'react-icons/fi';
import { BiMenuAltLeft } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';

export const icons = {
  FaHtml5: FaHtml5,
  FaCss3Alt: FaCss3Alt,
  FaSass: FaSass,
  SiMaterialui: SiMaterialui,
  SiJavascript: SiJavascript,
  FaReact: FaReact,
  SiTypescript: SiTypescript,
  FaNodeJs: FaNodeJs,
  SiExpress: SiExpress,
  DiMongodb: DiMongodb,
  GrMysql: GrMysql,
  DiGit: DiGit,
  MdOndemandVideo: MdOndemandVideo,
  BsBoxArrowUpRight: BsBoxArrowUpRight,
  AiFillGithub: AiFillGithub,
  FiSend: FiSend,
  FaHeart: FaHeart,
  AiOutlineFile: AiOutlineFile,
  SiGmail: SiGmail,
  SiLinkedin: SiLinkedin,
  SiGithub: SiGithub,
  FaDocker: FaDocker,
  BiMenuAltLeft: BiMenuAltLeft,
  CgClose: CgClose,
};
