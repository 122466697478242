export const projects = [
  {
    id: 6,
    title: 'ShareUrFavPlace',
    order: 'purple',
    image: '/assets/images/Project6.png',
    description:
      'A fully responsive full-stack web app that lets users to share/update/delete their favourite places (title, description, picture and location) in the world',
    techStack: [
      'HTML',
      'CSS',
      'SASS',
      'ReactJS',
      'NodeJS',
      'ExpressJS',
      'Mongoose',
      'Multer',
      'JWT',
      'MongoDB',
      'Rest API',
    ],
    gitHubUrl: 'https://github.com/veranika-karpava/share-fav-place',
    videoUrl: '/assets/videos/urfavplace.mp4',
    demoUrl: 'https://share-ur-fav-place.web.app/',
  },
  {
    id: 5,
    title: 'To-Do list',
    order: 'white',
    image: '/assets/images/Project5.png',
    description: 'A fully responsive full-stack web app that allows users to add/delete their tasks and set status to task as completed and delete completed task.',
    techStack: [
      'HTML',
      'CSS',
      'SASS',
      'ReactJS',
      'NodeJS',
      'ExpressJS',
      'Mongoose',
      'JWT',
      'MongoDB',
      'REST API',
    ],
    gitHubUrl: 'https://github.com/veranika-karpava/to-do-app-gardens',
    videoUrl: '/assets/videos/toDo.mp4',
    demoUrl: 'https://to-do-flowers-app.herokuapp.com/',
  },
  {
    id: 4,
    title: 'Xmas is Here',
    order: 'purple',
    image: '/assets/images/Project4.png',
    description:
      'A fully responsive full-stack Web App that allows user to count the days until Xmas, open movie cards every day, select Xmas movies randomly, add/remove movies to/from his Wishlist.',
    techStack: [
      'HTML',
      'CSS',
      'SASS',
      'ReactJS',
      'NodeJS',
      'ExpressJS',
      'JWT',
      'Web API',
      'Axios',
    ],
    gitHubUrl: 'https://github.com/veranika-karpava/xmas-is-here-capstone',
    videoUrl: '/assets/videos/xmas.mp4',
    demoUrl: '',
  },
  {
    id: 1,
    title: 'Where in the World',
    order: 'white',
    image: '/assets/images/Project10.png',
    description:
      'A user-friendly web app that lets users explore countries, switch between dark&light mode, search by name, filter by region, and discover interesting details about each country.',
    techStack: ['HTML', 'CSS', 'Styled Components', 'ReactJS', 'Fetch API','Context API'],
    gitHubUrl: 'https://github.com/veranika-karpava/where-in-the-world',
    videoUrl: '/assets/videos/whereInTheWorld.mp4',
    demoUrl: 'https://where-in-the-world-vk.netlify.app',
  },
  {
    id: 2,
    title: 'BandSite',
    order: 'purple',
    image: '/assets/images/Project2.png',
    description:
      'A fully responsive JS Web App that allows user to get information about musical band and add/delete/like comments',
    techStack: ['HTML', 'CSS', 'SASS', 'JavaScript', 'API'],
    gitHubUrl: 'https://github.com/veranika-karpava/veranika-karpava-bandsite',
    videoUrl: '/assets/videos/bandsite.mp4',
    demoUrl: 'https://bandsite-bees-knees.netlify.app',
  },
  {
    id: 3,
    title: 'BrainFlix',
    order: 'white',
    image: '/assets/images/Project3.png',
    description:
      'A fully responsive video streaming platform that allows user to select/upload a video and add/delete a comment',
    techStack: [
      'HTML',
      'CSS',
      'SASS',
      'ReactJS',
      'NodeJS',
      'ExpressJS',
      'Axios',
      'Web API',
      'JSON',
    ],
    gitHubUrl: 'https://github.com/veranika-karpava/veranika-karpava-brainflix',
    videoUrl: '/assets/videos/brainflix.mp4',
    demoUrl: '',
  },
];
