import React from 'react';

import './ProjectsList.scss';
import ProjectItem from '../ProjectItem/ProjectItem';
import { projects } from '../../data/projects';

const ProjectsList = () => {
  return (
    <section className="projects" id="projects">
      <h1 className="projects__heading">My Projects</h1>
      <ul className="projects__list">
        {projects.map(project => {
          return (
            <ProjectItem
              key={project.id}
              title={project.title}
              link={project.image}
              description={project.description}
              techStack={project.techStack}
              path={project.gitHubUrl}
              video={project.videoUrl}
              order={project.order}
              demo={project.demoUrl}
              dataAos="fade-right"
            />
          );
        })}
      </ul>
    </section>
  );
};

export default ProjectsList;
