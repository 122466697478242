import React from 'react';
import emailjs from 'emailjs-com';

import './Form.scss';
import DynamicReactIcon from '../DynamicReactIcon/DynamicReactIcon';

// env variable = REACT_APP_
const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;

const Form = ({ toSend, setToSend }) => {
  const handleChange = e => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, toSend, USER_ID)
      .then(res => {
        if (res.status === 200) {
          setToSend({
            name: '',
            whom: 'Veranika',
            message: '',
            email: '',
          });
        }
      })
      .catch(err => {
        console.log('FAILED...', err);
      });
  };

  return (
    <form
      className="contact__form"
      name="contact-form"
      id="contact_form"
      onSubmit={handleOnSubmit}
    >
      <div className="contact__container-form">
        <label htmlFor="name">
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            value={toSend.name}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div className="contact__container-form">
        <label htmlFor="email">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="email@email.com"
            value={toSend.email}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div className="contact__container-form">
        <label htmlFor="message">
          <textarea
            type="text"
            name="message"
            id="message"
            placeholder="Let's talk about..."
            rows={5}
            value={toSend.message}
            onChange={handleChange}
            required
          ></textarea>
        </label>
      </div>
      <button type="submit" className="contact__submit-button">
        Send
        <DynamicReactIcon name="FiSend" className="contact__icon-send" />
      </button>
    </form>
  );
};

export default Form;
