import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import './Button.scss';

const Button = ({ type, path, title, target, icon }) => {
  let btnDesign;
  switch (type) {
    case 'primary':
      btnDesign = 'button-primary';
      break;
    case 'circle':
      btnDesign = 'button-circle';
      break;
    default:
      btnDesign = 'button-primary';
      break;
  }

  return (
    <div className={btnDesign}>
      {path.includes('http') ? (
        <a href={path} className={`${btnDesign}__link`} target={target}>
          {title}
          {icon}
        </a>
      ) : (
        <NavLink to={path} className={`${btnDesign}__link`} target={target}>
          {title}
          {icon}
        </NavLink>
      )}
    </div>
  );
};

export default Button;
